// Materiali.jsx
import React from 'react';
import { useTranslation } from 'react-i18next';

const Materiali = () => {
  const { t } = useTranslation('global');

  return (
    <div className="main-content w-full pt-20 px-4 md:px-8 lg:px-16">
      {/* Main Header */}
      <h1 className="my-16 text-4xl md:text-5xl lg:text-6xl text-center uppercase font-bold text-gerussiRed">
        {t('materiali.h1')}
      </h1>

      {/* Section 1: Imbottitura per peluches */}
      <div className="mb-24 flex flex-col lg:flex-row items-center">
        {/* Text Content */}
        <div className="lg:w-1/2 lg:pr-8 lg:pl-8">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-gerussiRed mb-4">
            {t('materiali.sections.0.h2')}
          </h2>
          <p className="text-base md:text-lg text-gray-700 mb-6">
            {t('materiali.sections.0.text')}
          </p>
          <ul className="list-disc list-inside text-base md:text-lg text-gray-700">
            <li>{t('materiali.sections.0.machine_types.0')}</li>
            <li>{t('materiali.sections.0.machine_types.1')}</li>
          </ul>
        </div>

        {/* Image */}
        <div className="lg:w-1/2 mt-8 lg:mt-0">
          <img
            src={t('materiali.sections.0.img')}
            alt={t('materiali.sections.0.header')}
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
            loading="lazy"
          />
        </div>
      </div>

      {/* Section 2: Imbottitura per cuscini */}
      <div className="mb-24 flex flex-col lg:flex-row-reverse items-center">
        {/* Text Content */}
        <div className="lg:w-1/2 lg:pr-8 lg:pl-8">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-gerussiRed mb-4">
            {t('materiali.sections.1.h2')}
          </h2>
          <p className="text-base md:text-lg text-gray-700 mb-6">
            {t('materiali.sections.1.text')}
          </p>
          <ul className="list-disc list-inside text-base md:text-lg text-gray-700">
            <li>{t('materiali.sections.1.machine_types.0')}</li>
            <li>{t('materiali.sections.1.machine_types.1')}</li>
          </ul>
        </div>

        {/* Image */}
        <div className="lg:w-1/2 mt-8 lg:mt-0">
          <img
            src={t('materiali.sections.1.img')}
            alt={t('materiali.sections.1.header')}
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
            loading="lazy"
          />
        </div>
      </div>

      {/* Section 3: Imbottitura per divani */}
      <div className="mb-24 flex flex-col lg:flex-row items-center">
        {/* Text Content */}
        <div className="lg:w-1/2 lg:pr-8 lg:pl-8">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-gerussiRed mb-4">
            {t('materiali.sections.2.h2')}
          </h2>
          <p className="text-base md:text-lg text-gray-700 mb-6">
            {t('materiali.sections.2.text')}
          </p>
          <ul className="list-disc list-inside text-base md:text-lg text-gray-700">
            <li>{t('materiali.sections.2.machine_types.0')}</li>
            <li>{t('materiali.sections.2.machine_types.1')}</li>
          </ul>
        </div>

        {/* Image */}
        <div className="lg:w-1/2 mt-8 lg:mt-0">
          <img
            src={t('materiali.sections.2.img')}
            alt={t('materiali.sections.2.header')}
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
            loading="lazy"
          />
        </div>
      </div>

      {/* Section 4: Imbottitura per giacche */}
      <div className="mb-24 flex flex-col lg:flex-row-reverse items-center">
        {/* Text Content */}
        <div className="lg:w-1/2 lg:pr-8 lg:pl-8">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-gerussiRed mb-4">
            {t('materiali.sections.3.h2')}
          </h2>
          <p className="text-base md:text-lg text-gray-700 mb-6">
            {t('materiali.sections.3.text')}
          </p>
          <ul className="list-disc list-inside text-base md:text-lg text-gray-700">
            <li>{t('materiali.sections.3.machine_types.0')}</li>
            <li>{t('materiali.sections.3.machine_types.1')}</li>
          </ul>
        </div>

        {/* Image */}
        <div className="lg:w-1/2 mt-8 lg:mt-0">
          <img
            src={t('materiali.sections.3.img')}
            alt={t('materiali.sections.3.header')}
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
            loading="lazy"
          />
        </div>
      </div>

      {/* Section 5: Prova materiali */}
      <div className="mb-24 flex flex-col lg:flex-row items-center">
        {/* Text Content */}
        <div className="lg:w-1/2 lg:pr-8 lg:pl-8">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-gerussiRed mb-4">
            {t('materiali.sections.4.h2')}
          </h2>
          <p className="text-base md:text-lg text-gray-700 mb-6">
            {t('materiali.sections.4.text')}
          </p>
          <ul className="list-disc list-inside text-base md:text-lg text-gray-700">
            <li>{t('materiali.sections.4.machine_types.0')}</li>
            <li>{t('materiali.sections.4.machine_types.1')}</li>
          </ul>
        </div>

        {/* Image */}
        <div className="lg:w-1/2 mt-8 lg:mt-0">
          <img
            src={t('materiali.sections.4.img')}
            alt={t('materiali.sections.4.header')}
            className="w-full h-auto object-cover rounded-lg shadow-lg transform hover:scale-105 transition-transform duration-300"
            loading="lazy"
          />
        </div>
      </div>

      {/* Optional: Additional Styling or Call-to-Action */}
      {/* <div className="text-center mt-16">
        <button className="bg-gerussiRed text-white px-6 py-3 rounded-full hover:bg-red-600 transition-colors duration-300">
          Contattaci
        </button>
      </div> */}
    </div>
  );
};

export default Materiali;
