import React from 'react';
import { useTranslation } from 'react-i18next';
import LavoraConNoiForm from '../components/LavoraConNoiForm';

function Contacts() {
    const { t } = useTranslation("global");



    return  <div className="main-content transition-opacity duration-500 w-full pt-0">
    

    <LavoraConNoiForm />
      </div>
}

export default Contacts;