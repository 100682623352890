import React from 'react';
import { useTranslation } from 'react-i18next';
import ParallaxSection from '../components/ParallaxSection';
import ContactForm from '../components/Contact';
import Footer from '../components/Footer';
import VideoSection from '../components/VideoSection';

function Sectors() {
    const { t } = useTranslation("global");

    return <div className="main-content transition-opacity duration-500 w-full">
      <div className="grid grid-cols-2">
        <div className="flex flex-col  md:flex-col items-center pt-20 mb-10 px-2">
            <img src="/img/pet_pillows.webp" className="w-full lg:w-1/3 rounded-lg" />
            <h1 className="text-5xl text-center m-10 font-sans text-red-950 font-bold">{t('petbeds.h1')}</h1>
            <p className="text-center">{t('petbeds.p')}</p>

        </div>
        <div className="flex flex-col md:flex-col items-center pt-20 mb-10 px-2">
            <img src="/img/cuscions_pillows.webp" className="w-full lg:w-1/3 rounded-lg" />
            <h1 className="text-5xl text-center m-10 font-sans text-red-950 font-bold">{t('pillow.h1')}</h1>
            <p className="text-center">{t('pillow.p')}</p>

        </div>
        <div className="flex flex-col md:flex-col items-center pt-20 mb-10 px-2">
            <img src="/img/sofas.webp" className="w-full lg:w-1/3 rounded-lg" />
            <h1 className="text-5xl text-center m-10 font-sans text-red-950 font-bold">{t('sofas.h1')}</h1>
            <p className="text-center">{t('sofas.p')}</p>

        </div>
        <div className="flex flex-col md:flex-col items-center pt-20 mb-10 px-2">
            <img src="/img/peluches.webp" className="w-full lg:w-1/3 rounded-lg" />
            <h1 className="text-5xl text-center m-10 font-sans text-red-950 font-bold">{t('toys.h1')}</h1>
            <p className="text-center">{t('toys.p')}</p>

        </div>
        </div>
  
    <ContactForm />
  </div>
}

export default Sectors;