import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from '../../components/Contact';

const Gallery = ({ images, directionInverse = false, autoScrollSpeed = 1 }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isHovering, setIsHovering] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const animationRef = useRef(null);

  // Using ref for translateX to prevent unnecessary re-renders
  const translateXRef = useRef(0);

  // Ref to track if a drag has occurred
  const hasDraggedRef = useRef(false);

  // Duplicate images twice for seamless scrolling
  const duplicatedImages = [...images, ...images];

  const moveGallery = useCallback(() => {
    if (!isDragging) {
      const baseScrollAmount = directionInverse ? autoScrollSpeed : -autoScrollSpeed;
      const scrollAmount = isHovering ? baseScrollAmount * 0.3 : baseScrollAmount;

      translateXRef.current += scrollAmount;

      const singleSetWidth = contentRef.current.scrollWidth / 2; // Since we duplicated images twice

      // Reset translateX when it reaches the width of one set
      if (-translateXRef.current >= singleSetWidth) {
        translateXRef.current += singleSetWidth;
      } else if (translateXRef.current >= 0) {
        translateXRef.current -= singleSetWidth;
      }

      // Apply the transform directly for better performance
      contentRef.current.style.transform = `translateX(${translateXRef.current}px)`;
    }

    animationRef.current = requestAnimationFrame(moveGallery);
  }, [isDragging, isHovering, directionInverse, autoScrollSpeed]);

  useEffect(() => {
    animationRef.current = requestAnimationFrame(moveGallery);
    return () => {
      cancelAnimationFrame(animationRef.current);
    };
  }, [moveGallery]);

  // Mouse and Touch Handlers
  const handleDragStart = (clientX) => {
    setIsDragging(true);
    setStartX(clientX - translateXRef.current);
    hasDraggedRef.current = false; // Reset drag flag
  };

  const handleDragMove = (clientX) => {
    if (!isDragging) return;
    const walk = clientX - startX;
    translateXRef.current = walk;
    contentRef.current.style.transform = `translateX(${translateXRef.current}px)`;

    // Determine if the movement exceeds the drag threshold
    if (Math.abs(walk) > 5) { // 5 pixels threshold
      hasDraggedRef.current = true;
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setIsHovering(false);
    // Optionally, add some inertia or snap effect here
  };

  const handleMouseDown = (e) => handleDragStart(e.pageX);
  const handleMouseMove = (e) => handleDragMove(e.pageX);
  const handleMouseUp = (e) => handleDragEnd();
  const handleMouseLeave = (e) => handleDragEnd();

  const handleTouchStart = (e) => handleDragStart(e.touches[0].pageX);
  const handleTouchMove = (e) => handleDragMove(e.touches[0].pageX);
  const handleTouchEnd = (e) => handleDragEnd();

  // Image Click Handler
  const handleImageClick = (src) => {
    if (!hasDraggedRef.current) { // Only open modal if not dragged
      setSelectedImage(src);
    }
  };

  return (
    <div 
      className="relative overflow-hidden" 
      ref={containerRef}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={handleMouseLeave}
    >
      <div
        ref={contentRef}
        className="flex space-x-4 p-4 cursor-grab active:cursor-grabbing"
        style={{
          transform: `translateX(${translateXRef.current}px)`,
          transition: isDragging ? 'none' : 'none', // Remove transition for seamless scroll
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {duplicatedImages.map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Gallery image ${(index % images.length) + 1}`}
            className="w-[500px] h-[50vh] object-cover flex-shrink-0 transition-transform duration-300 hover:scale-105"
            draggable="false"
            onClick={() => handleImageClick(src)}
          />
        ))}
      </div>

      {/* Modal for Selected Image */}
      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 animate-fade-in"
          onClick={() => setSelectedImage(null)}
        >
          <div 
            className="relative max-w-4xl w-full animate-scale-in"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <img 
              src={selectedImage} 
              alt="Selected" 
              className="w-full h-auto max-h-[90vh] object-contain"
            />
            <button
              onClick={() => setSelectedImage(null)}
              className="absolute top-4 right-4 text-white bg-red-500 rounded-full p-2 w-10 h-10 flex items-center justify-center text-xl font-bold transition-transform duration-300 hover:scale-110"
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
function Prova() {
  const { t } = useTranslation("global");

  const images1 = [
    '../img/Test-macchine-per-imbottitura-Gerussi.webp',
    '../img/Test-macchine-per-imbottiti-Gerussi.webp',
    '../img/Test-macchine-per-imbottire-Gerussi.webp',
    '../img/Test-macchine-da-imbottitura-Gerussi.webp',
    '../img/Sfilacciatrice-per-scarti-tessili-SF2CA-Gerussi.webp',
  ];
  
  const images2 = [
    '../img/Materiale-spugna-per-imbottitura-Gerussi.webp',
    '../img/Materiale-Piuma-per-imbottitura-Gerussi.webp',
    '../img/Materiale-fibroso-per-imbottitura-Gerussi.webp',
    '../img/Materiali-fibra-per-imbottitura-Gerussi.webp',
  ];

  return (
    <div className="main-content transition-opacity duration-500 w-full pt-20">
      <h1 className='my-20 text-4xl md:text-4xl lg:text-6xl text-center uppercase font-bold text-gerussiRed mb-3'>
      {t('mat.h1')}
      </h1>

      <Gallery images={images1} directionInverse={false} autoScrollSpeed={1} />
      <div className="h-auto md:h-[40vh] px-4 pb-10 lg:px-10 xl:px-40 py-10 w-full text-left">
        <h2 className='text-4xl md:text-5xl font-bold text-gerussiRed mb-3'>{t('mat.visita')}</h2>
        <p className="text-sm md:text-base">{t('mat.p')}</p>
      </div>
  

    <div className='flex flex-col lg:flex-row lg:pr-5 mb-10'>  
      <div className='w-full lg:w-2/3'>
        <Gallery images={images2} directionInverse={true} autoScrollSpeed={1} />
      </div>
      <div className="w-full lg:w-1/3 px-4  py-10  text-left">
      <h2 className='text-4xl md:text-5xl font-bold text-gerussiRed mb-3'>{t('mat.prova')}</h2>
        <p className="text-sm md:text-base">{t('mat.p1')}</p>
</div>
    </div>



      <ContactForm />
    </div>
  );
}

export default Prova;