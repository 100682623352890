import React from 'react';
import getPathByKey from '../utils/getPathByKey ';
import LocalizedNavLink from '../utils/LocalizedNavLink';


const FlipCard = ({ Image, text, to }) => {
  const path = getPathByKey(to);
  return (
    
    <LocalizedNavLink to={path} className="group block">
    <div className="relative w-full h-0 pb-[56.25%] border border-gray-300 rounded-lg overflow-hidden group">
      <div className="absolute inset-0 transition duration-500 ease-in-out bg-gray-800 opacity-0 group-hover:opacity-100">
        <div className="flex justify-center items-center h-full">
          <img src={Image} alt="Back Image" className="object-cover w-full h-full" />
          <div className="absolute inset-0 bg-red-800 opacity-50"></div>
          <div className="absolute inset-0 flex justify-center items-end py-4 text-white text-lg lg:text-2xl font-light">
            {text}
          </div>
        </div>
      </div>
      <div className="absolute inset-0 transition duration-500 ease-in-out bg-white opacity-100 group-hover:opacity-0">
        <div className="flex justify-center items-center h-full">
          <img src={Image} alt="Front Image" className="object-cover w-full h-full" />
          <div className="absolute inset-0 flex justify-center items-end py-4 text-white text-lg lg:text-2xl font-light">
            {text}
          </div>
        </div>
      </div>
    </div>
    </LocalizedNavLink>
  );
};

export default FlipCard;