import React from 'react';
import { useTranslation } from 'react-i18next';
import ParallaxSection from '../components/ParallaxSection';
import ContactForm from '../components/Contact';
import Footer from '../components/Footer';
import VideoSection from '../components/VideoSection';

function Lavorazioni() {
    const { t } = useTranslation("global");

    return        <div className="main-content transition-opacity duration-500 w-full">


    <div className=" bg-[url('/img/Studi-meccanici-particolare-officina-gerussi-spilimbergo.webp')] bg-cover bg-fixed bg-bottom relative h-screen ">
      <div className="absolute bottom-4 z-20 h-auto md:h-[60vh] px-4  py-10 w-full text-center">
              
            <h1 className='text-4xl md:text-6xl lg:text-8xl uppercase font-bold text-white mb-3'>{t('lav.h1')}</h1>
            
        </div>
      </div>


        {/*   <VideoSection src="../img/PreviewTetto.mp4"></VideoSection> */}


    <div className=" bg-[url('/img/Studi-meccanici-particolare-officina-gerussi-spilimbergo.webp')] bg-cover bg-fixed bg-bottom 
    pt-10 md:px-10 lg:px-20 2xl:px-40 pb-10">
 
    <div className='bg-white p-10'>


      <div className="mb-40 mt-10 flex relative flex-col lg:flex-row lg:h-[80vh]">
        <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute left-0 lg:-translate-x-1/2 w-full lg:w-3/5' src="/img/Aprifibra-per-imbottitura-CD4001-Gerussi.webp"/>
        </div>
          <div className='w-full lg:w-3/5 mt-4 self-center'>
          <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat1h')}</h3>
          <p className="text-base">{t('lav.cat1p')}</p>
          <a href="/contacts" className="text-gerussiRed underline">{t('lav.infoLink')}</a>
        </div>
      </div>

      <div className="mb-40 flex relative flex-col lg:flex-row-reverse lg:h-[80vh]">
      <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute ' src="/img/Sfilacciatrice-di-scarti-tessili-SF2CA-Gerussi.webp"/>
        </div>
        <div className='w-full lg:w-3/5 mt-4 self-center'>
            <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat2h')}</h3>
            <p className="text-base">{t('lav.cat2p')}</p>
            <a href="/contacts" className="text-gerussiRed underline">{t('lav.infoLink')}</a>
        </div>
      </div>

      <div className="mb-40 flex relative flex-col lg:flex-row lg:h-[80vh]">
      <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute left-0 lg:-translate-x-1/2 w-full lg:w-3/5' src="/img/Riempitrice-R-3PV2-Gerussi.webp"/>
        </div>
        <div className='w-full lg:w-3/5 mt-4 self-center'>
          <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat3h')}</h3>
          <p className="text-base">{t('lav.cat3p')}</p>
          <a href="/contacts" className="text-gerussiRed underline">{t('lav.infoLink')}</a>
        </div>
      </div>

      <div className="mb-40 flex relative flex-col lg:flex-row-reverse lg:h-[80vh]">
      <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute lg:-translate-x-1/4 w-[80%]' src="/img/Rimepitrice-con-pesatura-automatica-per-imbottire-SR5HMI.webp"/>
        </div>
        <div className='w-full lg:w-3/5 mt-4 self-center'>
            <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat4h')}</h3>
            <p className="text-base">{t('lav.cat4p')}</p>
            <a href="/contacts" className="text-gerussiRed underline">{t('lav.cat1h')}</a>
        </div>
      </div>

      <div className="mb-40 flex relative flex-col lg:flex-row lg:h-[80vh]">
      <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute left-0 lg:-translate-x-1/3 w-full lg:w-2/5' src="/img/Mescolatore-per-materiali-da-imbottitura-M1P-Gerussi.webp"/>
        </div>
        <div className='w-full lg:w-3/5 mt-4 self-center'>
          <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat5h')}</h3>
          <p className="text-base">{t('lav.cat5p')}</p>
          <a href="/contacts" className="text-gerussiRed underline">{t('lav.infoLink')}</a>
        </div>
      </div>

      <div className="mb-40 flex relative flex-col lg:flex-row-reverse lg:h-[60vh]">
      <div className='w-full lg:w-2/5'>
          <img className='relative lg:absolute lg:translate-y-20 lg:translate-x-20' src="/img/Console-for-filling-machines-Gerussi-1.webp"/>
        </div>
        <div className='w-full lg:w-3/5 mt-4 self-center'>
            <h3 className="text-3xl font-light uppercase text-gerussiRed mb-2">{t('lav.cat6h')}</h3>
            <p className="text-base">{t('lav.cat6p')}</p>
            <a href="/contacts" className="text-gerussiRed underline">{t('lav.infoLink')}</a>
        </div>
      </div>

    </div>
    </div>
    <ContactForm />
  </div>
}

export default Lavorazioni;