import { useTranslation } from "react-i18next";
import FlipImage from "../../components/FlipImage";
import ContactForm from "../../components/Contact";

export default function PetBeds() {

    const { t } = useTranslation("global");
    
    return <>
        
        <div className={`main-content transition-opacity duration-500 overflow-x-hidden`}>
        
        <div className="flex flex-col md:flex-col items-center pt-20 mb-10 px-2">
            <img src="/img/pet_pillows.webp" className="w-full lg:w-1/3 rounded-lg" />
            <h1 className="text-5xl text-center m-10 font-sans text-red-950 font-bold">{t('petbeds.h1')}</h1>
            <p className="text-center">{t('petbeds.p')}</p>

        </div>
        <section className="relative flex flex-col justify-center bg-red-950 items-center max-w-screen-xl py-20 sm:px-5 lg:px-10 md:px-10">
          <h1 className="text-5xl text-center m-10 font-sans text-white font-bold">{t('home.h2')}</h1>
          <div className="grid grid-cols-1 font-light lg:p-0 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 px-2 gap-4 ">
            <FlipImage to={'/macchinari-pesare-dosare'} text={t('home.cat3')} imageFront="../img/sr5-vettoriale.png" imageBack="../img/Rimepitrice-con-pesatura-automatica-per-imbottire-SR5HMI.webp" />
            <FlipImage to={'/macchinari-riciclo-scarti'} text={t('home.cat5')} imageFront="../img/sf2ca-vettoriale.png" imageBack="../img/Sfilacciatrice-di-scarti-tessili-SF2CA-Gerussi.webp" />
            <FlipImage to={'/automazione-trasporto-filtraggio'} text={t('home.cat6')} imageFront="../img/console-vettoriale.png" imageBack="../img/Console-for-filling-machines-Gerussi-1.webp" />
          </div>
        </section>
        <ContactForm />
        </div>
    </>
}