import { useTranslation } from "react-i18next";
import FlipImage from "../../components/FlipImage";
import ContactForm from "../../components/Contact";

export default function Sofas() {

    const { t } = useTranslation("global");
    
    return <>
        
        <div className={`main-content transition-opacity duration-500 overflow-x-hidden`}>
        
        <div className="flex flex-col md:flex-col items-center pt-20 mb-10 px-2">
            <img src="/img/sofas.webp" className="w-full lg:w-1/3 rounded-lg" />
            <h1 className="text-5xl text-center m-10 font-sans text-red-950 font-bold">{t('sofas.h1')}</h1>
            <p className="text-center">{t('sofas.p')}</p>

        </div>
        <section className="relative flex flex-col justify-center bg-red-950 items-center max-w-screen-xl py-20 sm:px-5 lg:px-10 md:px-10">
          <h1 className="text-5xl text-center m-10 font-sans text-white font-bold">{t('home.h2')}</h1>
          <div className="grid grid-cols-1 font-light lg:p-0 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 px-2 gap-4 ">
            <FlipImage to={'/macchinari-preparare-aprire'} text={t('home.cat1')} imageFront="../img/cd400-vettoriale.png" imageBack="../img/Aprifibra-per-imbottitura-CD4001-Gerussi.webp" />
            <FlipImage to={'/macchinari-pesare-dosare'} text={t('home.cat3')} imageFront="../img/sr5-vettoriale.png" imageBack="../img/Rimepitrice-con-pesatura-automatica-per-imbottire-SR5HMI.webp" />
            <FlipImage to={'/macchinari-riempire-foderare'} text={t('home.cat4')} imageFront="../img/riempire-vettoriale.png" imageBack="../img/Riempitrice-R-3PV2-Gerussi.webp" />
          </div>
        </section>
        <ContactForm />
        </div>
    </>
}