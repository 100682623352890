import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faArrowDown, faArrowUp, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import ContactForm from '../components/Contact';
import Footer from '../components/Footer';
import LavoraConNoiForm from '../components/LavoraConNoiForm';

function Contacts() {
    const { t } = useTranslation("global");



    return  <div className="main-content transition-opacity duration-500 w-full pt-0">
    
   {/*  <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2765.749701817476!2d12.883630676027114!3d46.11589427109212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477a2ae7ecd2cf17%3A0xf1ae2ac39becfe26!2sGERUSSI%20di%20Alessio%20Gerussi%20%26%20C.%20sas!5e0!3m2!1sen!2sit!4v1723300518110!5m2!1sen!2sit"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  aria-hidden="false"
              /> */}
              <div /* className="bg-[url('../img/mappaGerussi.png')] bg-cover h-[60vh]" */>
                <img src='../img/mappaGerussi.png' className='' />
              </div>
    <ContactForm />
      </div>
}

export default Contacts;