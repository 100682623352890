
import FlipCard from '../components/FlipCard'
import FlipImage from '../components/FlipImage'
import ParallaxSection from '../components/ParallaxSection'
import AnimatedCounter from '../components/AnimatedCounter'
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactForm from '../components/Contact';
import getPathByKey from '../utils/getPathByKey ';
import LocalizedNavLink from '../utils/LocalizedNavLink';

export default function Home() {

    const [videoReady, setVideoReady] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [t, i18n] = useTranslation("global");
  
    useEffect(() => {
      const videoElement = document.getElementById("background-video");
      if (videoElement) {
        console.log("Video element found!")
        
        const handleCanPlayThrough = () => {
          console.log("Video is ready to play through!")
          setVideoReady(true);
          setIsLoading(false);
        };
        videoElement.addEventListener("canplaythrough", handleCanPlayThrough);
        
        return () => {
          console.log("Cleanup: Removing event listener")
          videoElement.removeEventListener("canplaythrough", handleCanPlayThrough);
        };
      } else {
        console.log("Video element not found!")
      }
    }, []);
  
  /*   useEffect(() => {
      const loadingTimeout = setTimeout(() => {
        setIsLoading(false);
      }, 500); 
  
      return () => clearTimeout(loadingTimeout);
    }, []) */
  
    return <>
          
        {/* Main content */}
        <div className={`main-content transition-opacity duration-500 overflow-x-hidden `}>
        
          <div className="h-[60vh] lg:h-screen flex flex-col">
            <section className="flex-1 relative">
              {/* Immagine di fallback */}
              {isLoading && (
                <img
                  src="../img/videofallback.webp" // Sostituisci con il percorso della tua immagine
                  alt="Immagine di Fallback"
                  className="absolute inset-0 w-full h-full object-cover"
                />
              )}

              {/* Video di sfondo */}
              <video
                id="background-video"
                autoPlay
                muted
                loop
                className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${videoReady ? 'opacity-100' : 'opacity-0'}`}
              >
                <source src="../img/videoplayback.mp4" type="video/mp4" />
                Il tuo browser non supporta il tag video.
              </video>
              <h1 className="text-4xl lg:text-6xl uppercase w-2/3 absolute left-1/2 -translate-x-1/2 bottom-10 text-white text-center font-bold">{t('home.h1')}</h1>
            </section>
          </div>
          <section className="relative flex flex-col justify-center items-center max-w-screen-xl sm:px-5 py-5 lg:py-10 lg:px-10 md:px-10">
           
            <div className="grid font-light lg:p-0 grid-cols-1 lg:grid-cols-4 gap-4 px-2 w-full">
              <FlipCard to='Pillows' text={t('home.card1')} Image="../img/Video senza titolo - Realizzato con Clipchamp.gif" />
              <FlipCard to='Sofas' text={t('home.card2')} Image="../img/Video senza titolo - Realizzato con Clipchamp (1).gif" />
              <FlipCard to='Toys' text={t('home.card3')} Image="../img/Video senza titolo - Realizzato con Clipchamp (2).gif" />
              <FlipCard to='PetBeds' text={t('home.card4')} Image="../img/pet_pillows.webp" />
            </div>
          </section>
  
          
          <section className="relative flex flex-col justify-center bg-red-950 items-center max-w-screen-xl py-20 sm:px-5 lg:px-10 md:px-10">
            <h2 className="text-4xl uppercase text-center m-10 font-sans text-white font-bold">{t('home.h2')}</h2>
            <div className="grid grid-cols-2 font-light lg:p-0 lg:grid-cols-3 xl:grid-cols-4 px-2 gap-4 ">
              <FlipImage to='PrepareOpen' text={t('home.cat1')} imageFront="../img/cd400-vettoriale.png" imageBack="../img/Aprifibra-per-imbottitura-CD4001-Gerussi.webp" />
              <FlipImage to='Recycling' text={t('home.cat2')} imageFront="../img/m1-vettoriale.png" imageBack="../img/Mescolatore-per-materiali-da-imbottitura-M1P-Gerussi.webp" />
              <FlipImage to='FillLine' text={t('home.cat3')} imageFront="../img/sr5-vettoriale.png" imageBack="../img/Rimepitrice-con-pesatura-automatica-per-imbottire-SR5HMI.webp" />
              <FlipImage to='WeighDose' text={t('home.cat4')} imageFront="../img/riempire-vettoriale.png" imageBack="../img/Riempitrice-R-3PV2-Gerussi.webp" />
              <FlipImage to='MixStoreTransform' text={t('home.cat5')} imageFront="../img/sf2ca-vettoriale.png" imageBack="../img/Sfilacciatrice-di-scarti-tessili-SF2CA-Gerussi.webp" />
              <FlipImage to='AutomationTransportFiltering' text={t('home.cat6')} imageFront="../img/console-vettoriale.png" imageBack="../img/Console-for-filling-machines-Gerussi-1.webp" />
            </div>
          </section>

          <section className="relative flex flex-col justify-center items-center max-w-screen-xl sm:px-5 py-5 lg:py-10 lg:px-10 md:px-10">
          <h3 className="my-16 text-4xl md:text-5xl lg:text-6xl text-center uppercase font-bold text-gerussiRed">
            {t('materiali.h1')}
          </h3>
           <div className="grid font-light lg:p-0 grid-cols-1 lg:grid-cols-4 mb-20 gap-4 px-2 w-full">
             <FlipCard to='matToy' text={t('materiali.sections.0.header')} Image={t('materiali.sections.0.img')} />
             <FlipCard to='matPillow' text={t('materiali.sections.1.header')} Image={t('materiali.sections.1.img')} />
             <FlipCard to='matSofa' text={t('materiali.sections.2.header')} Image={t('materiali.sections.2.img')} />
             <FlipCard to='matBlanket' text={t('materiali.sections.3.header')} Image={t('materiali.sections.3.img')} />
           </div>
         </section>

          <ParallaxSection image="../img/gerussi_shot.jpg" >
          <div className='max-w-[80%] mb-10 rounded-lg pt-5 lg:pt-20 px-2 lg:px-10  bg-[#ffffffcf]'>
            <div className='flex flex-col md:flex-row px-5 gap-5 mb-5'>
              <h3 className="w-full md:w-1/3 uppercase text-3xl md:text-5xl text-left font-bold  mb-2 z-10">{t('home.chi')}</h3>
              <p className='text-sm w-full md:w-2/3 text-left leading-tight'>{t('home.chisiamo')}</p>
            </div>
            
            <div className='flex flex-col md:flex-row gap-5 mb-5'>
              <div className='w-full md:w-1/3 px-5'>
              <LocalizedNavLink to={getPathByKey('who')} className="mt-2 text-gerussiRed ">{t('home.scoprichisiamo')}</LocalizedNavLink>
              </div>
            <div className="flex flex-wrap">
                <div className="w-1/2 lg:w-1/4 px-0 lg:px-4 mb-0 lg:mb-8">
                  <div className=" rounded-lg p-1 lg:p-3">
                    <div className="align-middle  mb-1">
                      <AnimatedCounter target={new Date().getFullYear() - 1957} speed={20} sub={''} />
                      <p className="mt-1 leading-tight text-center font-bold">{t('home.anni')}</p>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/4 px-0 lg:px-4 mb-0 lg:mb-8">
                  <div className=" rounded-lg p-1 lg:p-3">
                    <div className="align-middle  mb-1">
                      <AnimatedCounter target={70} speed={20} sub={'+'} />
                      <p className="mt-1 leading-tight text-center font-bold">{t('home.paesi')}</p>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/4 px-0 lg:px-4 mb-0 lg:mb-8">
                  <div className=" rounded-lg p-1 lg:p-3">
                    <div className="align-middle  mb-1">
                      <AnimatedCounter target={1300} speed={0.08} sub={'+'} />
                      <p className="mt-1 leading-tight text-center font-bold">{t('home.clienti')}</p>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 lg:w-1/4 px-0 lg:px-4 mb-0 lg:mb-8">
                  <div className=" rounded-lg p-1 lg:p-3">
                    <div className="align-middle  mb-1">
                      <AnimatedCounter target={100} speed={15} sub={'%'} />
                      <p className="mt-1 leading-tight text-center font-bold">Made in Italy</p>
                    </div>
                  </div>
                </div>
              </div>
            </div></div>
        </ParallaxSection>
  
  
  
          <section className="relative flex flex-col justify-center items-center max-w-screen-xl sm:px-5 pt-24 pb-32 lg:px-10 md:px-10">
            <h3 className="text-3xl lg:text-5xl uppercase text-center font-bold">{t('home.h3')}</h3>
            <div className="grid grid-cols-1 gap-8 mt-10 sm:grid-cols-1 p-2 lg:p-10 leading-[1.1] lg:grid-cols-3">
              <div className="flex flex-row gap-2">
                <div className="w-1/4 md:w-1/4 mb-4 md:mb-0">
                  <img src="../img/50-anni-icona-gerussi.png" alt="Image" className="w-full xl:w-3/5 h-auto md:w-full" />
                </div>
                <div className="w-full md:w-3/4 md:pl-4">
                  <h5 className="text-xl font-bold mb-2">{t('home.vanto1T')}</h5>
                  <p>
                  {t('home.vanto1P')}
                  </p>
                </div>
              </div>
  
              <div className="flex flex-row gap-2">
                <div className="w-1/4 md:w-1/4 mb-4 md:mb-0">
                  <img src="../img/Layout-Gerussi.webp" alt="Image" className="w-full xl:w-3/5 h-auto md:w-full" />
                </div>
                <div className="w-full md:w-3/4 md:pl-4">
                  <h5 className="text-xl font-bold mb-2">{t('home.vanto2T')}</h5>
                  <p>{t('home.vanto2P')}
                  </p>
                </div>
              </div>
  
              <div className="flex flex-row gap-2">
                <div className="w-1/4 md:w-1/4 mb-4 md:mb-0">
                  <img src="../img/Prestazioni-Gerussi.png" alt="Image" className="w-full xl:w-3/5 h-auto md:w-full" />
                </div>
                <div className="w-full md:w-3/4 md:pl-4">
                  <h5 className="text-xl font-bold mb-2">{t('home.vanto3T')}</h5>
                  <p>{t('home.vanto3P')}</p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-1/4 md:w-1/4 mb-4 md:mb-0">
                  <img src="../img/Efficienza-Gerussi.png" alt="Image" className="w-full xl:w-3/5 h-auto md:w-full" />
                </div>
                <div className="w-full md:w-3/4 md:pl-4">
                  <h5 className="text-xl font-bold mb-2">{t('home.vanto4T')}</h5>
                  <p>{t('home.vanto4P')}</p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-1/4 md:w-1/4 mb-4 md:mb-0">
                  <img src="../img/Affidabilita-Gerussi.png" alt="Image" className="w-full xl:w-3/5 h-auto md:w-full" />
                </div>
                <div className="w-full md:w-3/4 md:pl-4">
                  <h5 className="text-xl font-bold mb-2">{t('home.vanto5T')}</h5>
                  <p>{t('home.vanto5P')}</p>
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="w-1/4 md:w-1/4 mb-4 md:mb-0">
                  <img src="../img/100-gerussi-logo.svg" alt="Image" className="w-full xl:w-3/5 h-auto md:w-full" />
                </div>
                <div className="w-full md:w-3/4 md:pl-4">
                  <h5 className="text-xl font-bold mb-2">{t('home.vanto6T')}</h5>
                  <p>{t('home.vanto6P')}</p>
                </div>
              </div>
  
  
            </div>
          </section>
  
          <section className="w-full justify-center bg-[#202020]">
            <div className="flex flex-col lg:flex-row h-auto py-10">
              <iframe
                src="https://agerussi.com/3d-logo/"
                className="w-full h-[50vh] lg:h-[80vh]"
              />
              <div className="w-full text-white content-center p-5">
                <h4 className="text-3xl uppercase font-light mb-3">{t('home.servizi.titolo')}</h4>
                <p>{t('home.servizi.p')}</p>
                <LocalizedNavLink to={getPathByKey('servizi')} className="text-gerussiRed ">{t('home.consulenzaBtn')}</LocalizedNavLink>
                <div className='flex flex-col mt-5 gap-4'>
                  <div className=''>
                    <h5 className='font-bold text-2xl'>{t('home.servizi.consulenza')}</h5>
                    <p>{t('home.servizi.consulenzaP')}</p>
                  </div>
                  <div className=''>
                    <h5 className='font-bold text-2xl'>{t('home.servizi.assistenza')}</h5>
                    <p>{t('home.servizi.assistenzaP')}</p>
                  </div>
                  <div className=''>
                    <h5 className='font-bold text-2xl'>{t('home.servizi.formazione')}</h5>
                    <p>{t('home.servizi.formazioneP')}</p>
                  </div>
                  <div className=''>
                    <h5 className='font-bold text-2xl'>{t('home.servizi.mannutenzione')}</h5>
                    <p>{t('home.servizi.mannutenzioneP')}</p>
                  </div>
                  <div className=''>
                    <h5 className='font-bold text-2xl'>{t('home.servizi.ricambi')}</h5>
                    <p>{t('home.servizi.ricambiP')}</p>
                  </div>
                </div>
                </div>
            </div>
          </section>

          <section className="relative flex flex-col justify-center bg-green-100 items-center max-w-screen-xl py-20 px-2 sm:px-5 lg:px-10 md:px-10">
            <h5 className="text-3xl lg:text-5xl uppercase text-center mb-10 font-sans text-green-800 font-bold">{t('green.h1')}</h5>
            <p className="text-center max-w-3xl mb-10 text-green-700">{t('green.p')}</p>
            <LocalizedNavLink to={getPathByKey('green')} className="w-full text-center text-green-500 hover:underline">
              {t('green.infoLink')}
            </LocalizedNavLink>
          </section>
      
      <ContactForm />
  
        </div>
  
     
  
    </>
  }
  
  // 3D Iframe Component with Lazy Loading
  export const LazyLoad3DIframe = ({ src, title }) => {
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.1,
    });
  
    return (
      <div ref={ref} className="h-full w-full">
        {inView ? (
          <iframe
            src={src}
            title={title}
            className="w-full h-full"
          />
        ) : (
          <div className="w-full h-full flex items-center justify-center bg-gray-200">
            Loading 3D View...
          </div>
        )}
      </div>
    );
  };


  